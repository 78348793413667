import Seo from 'src/components/seo/Seo'
import MainFormRegisterbicycle from 'src/components/servicos/ServicesPage/GarantiaVitalicia/RegistreBicicleta'

function Page() {
  return (
    <>
      <Seo title="Registre sua Bicicleta | Decathlon" />
      <MainFormRegisterbicycle />
    </>
  )
}

export default Page
